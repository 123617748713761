:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-primary-text: #f0ecff;
  --color-red: #aa0000;
  --color-primary-light: #ac9ecb;
  --color-grey-light: #f2f2f2;
  --color-primary-background: linear-gradient(13deg, #0d0d0d, #555);
  --primary-gradient: linear-gradient(13deg, #aa0000, #ff6992);
  --secondary-gradient: linear-gradient(13deg, #e13939, #f8789b);
  --primary-grey: #383838;
}
/*breakpoint mixin start here*/
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';
/*breakpoint mixin end here*/
