@import 'variable';
:root {
  --primary-font: 'Rubik', sans-serif;
  --secondary-font: 'Play', sans-serif;
  --primary: #ff4f44;
}
html,
body {
  height: 100%;
}
#root {
  height: 100%;
  .ant-app {
    height: 100%;
  }
  .wrapper {
    height: 100%;
  }
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-decoration: inherit;
  vertical-align: inherit;
}
//=========================================
/********** margin padding gap function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 2, 6, 4, 8, 12, 16, 20, 24, 32, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .gap-@{value} {
      gap: ~'@{value}px';
    }

    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }
  }
}

.make-space();

.m-auto {
  margin: auto;
}

//=========================================
/********** margin padding gap function end here **************/
//=========================================
//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}
.vertical-middle {
  vertical-align: middle;
}
//=========================================
/********** text alignment end here **************/
//=========================================
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen1024 {
    display: block;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

//border style
.b-0 {
  border: none;
}

.b-1 {
  border-spacing: 1px;
}

.b-2 {
  border-spacing: 2px;
}

.b-3 {
  border-spacing: 3px;
}

.b-solid {
  border-style: solid;
}

//border style
/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}
/*opacity class list*/
.opacity-1 {
  opacity: 1;
}
.opacity-8 {
  opacity: 0.8;
}
.opacity-6 {
  opacity: 0.6;
}
.opacity-4 {
  opacity: 0.4;
}
.opacity-2 {
  opacity: 0.2;
}
.opacity-0 {
  opacity: 0;
}
.no-padding-wrapper {
  .wrapper {
    padding: 0;
  }
}
.wrapper {
  padding: 32px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media @screen991 {
    font-size: 14px;
    padding: 24px;
  }

  @media @screen767 {
    padding: 16px;
    padding-bottom: 0;
  }
}
.container {
  margin: auto;
  width: 100%;
  padding: 0 48px;

  @media @screen1150 {
    padding: 0 24px;
  }

  @media @screen767 {
    padding: 0 16px;
  }
}

.responsive-table {
  width: 100%;
  overflow: auto;
}

.ant-spin-nested-loading > div > .ant-spin {
  height: 100vh;
}

.ant-spin-dot-item {
  background: var(--primary-gradient);
}
.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-btn-primary {
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  border: none;
  font-size: 22px;
  line-height: normal;
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}
.ant-btn-primary:disabled {
  background: #836d6d;
  color: #e4d5d5;
}

//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.text-heading {
  font-size: 46px;
  line-height: 150%;
  font-weight: 700;
  color: var(--color-primary-text);
  @media @screen767 {
    font-size: 26px;
  }
}

//=========================================
/********** text alignment end here **************/
//=========================================

//=========================================
/********** flex box class start here **************/
//=========================================

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

//=========================================
/********** flex box class end here **************/
//=========================================

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

//width classes

.site-result-demo-error-icon {
  color: @colorError;
}

//border style
.b-0 {
  border: none;
}

//border style

/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/*position class end here*/

//CUSTOM STYLE CLASSES END

.header-wrapper {
  .d-flex;
  .justify-between;
  .align-center;
  .logo {
    img {
      display: block;
      vertical-align: middle;
      height: 34px;
      @media @screen767 {
        height: 24px;
      }
    }
  }
}

.sticky-action-form {
  .ant-card-head-title {
    @media @screen767 {
      padding: 0;
    }
  }

  .ant-page-header-heading {
    @media @screen767 {
      padding: 0;
      min-height: 48px;
    }
  }
}

#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  font-family:
    PuHuiTi,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    apple color emoji,
    segoe ui emoji,
    Segoe UI Symbol,
    noto color emoji,
    sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .d-flex;
  .align-center;
  .justify-center;
  color: var(--color-black);

  img {
    height: 32px;
  }
}

.delete-icon {
  color: var(--color-red);
  cursor: pointer;
}

.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
}

/*sidebar custom less start here*/
.responsive-logo {
  display: flex;
  img {
    width: 44px;
  }
}
.ant-app {
  height: 100%;
}

.ant-layout {
  background: var(--color-primary-background);
  height: 100%;
  .ant-layout-sider {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    &.close {
      width: 0;
      max-width: 0;
      min-width: 0;
      left: 0;
    }
    @media @screen767 {
      width: 0;
      max-width: 0;
      position: fixed;
      height: 100%;
      left: -240px;
      z-index: 12;
    }
  }
}

.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ant-layout.ant-layout-has-sider {
  height: 100vh;
}

header.ant-layout-header.site-layout-sub-header-background.app-header {
  padding: 0 32px;
  line-height: 64px;
  background: var(--primary-gradient);
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media @screen767 {
    padding: 0 16px;
  }
}

.overlay-disable {
  @media @screen767 {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: var(--color-black);
    opacity: 0.6;
    z-index: 11;
  }
}

/*sidebar custom less end here*/
.ant-layout-footer {
  @media @screen767 {
    padding: 16px 50px;
  }
}

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

:hover::-webkit-scrollbar-thumb {
  border-left: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;

  .ant-card-head {
    position: relative;
    z-index: 1;
  }

  .ant-page-header-heading {
    min-height: 48px;
  }

  > .ant-card-body {
    flex: 1 1;
    height: 100%;
    padding: 0;
    overflow: hidden;

    .card-body-wrapper {
      height: 100%;
      overflow: auto;
      padding: 24px;
    }
  }
}

.ant-card-small {
  > .ant-card-body {
    .card-body-wrapper {
      padding: 12px;
    }
  }
}

.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}

.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
  svg {
    margin-right: 5px;
    font-size: 16px;
  }
}
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media @screen767 {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .page-header-left {
    text-transform: uppercase;
    span {
      display: inline-block;
      color: var(--primary);
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 2.2px;
    }
    h2 {
      margin-top: 0;
      font-size: 42px;
      @media @screen767 {
        font-size: 32px;
      }
    }
  }
  .page-header-right {
    .ant-btn {
      font-size: 16px;
      height: 46px;
    }
  }
}
.tournament__list-item-wrapper {
  .tournament__list-item {
    position: relative;
    margin: 0 0 25px;
    z-index: 1;
    background: #4c4c4c;
    padding: 16px;
    &:before {
      content: '';
      position: absolute;
      left: 13%;
      top: 19px;
      width: 200px;
      height: 60px;
      background: var(--tg-theme-primary);
      transition: all 0.3s ease-out 0s;
      z-index: -1;
    }
    .background-path {
      fill: var(--tg-border-1);
      stroke: #4c4c4c;
      stroke-width: 0.25px;
      fill-rule: evenodd;
    }
    svg {
      display: block;
      width: 100%;
    }
  }
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--primary);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}
.bid-wrapper {
  .ant-segmented .ant-segmented-item-label {
    height: 2.5vw;
    width: 100%;
    min-width: 32px;
    line-height: clamp(32px, 2.5vw, 2.5vw);
    padding: 0;
  }
  .ant-btn {
    height: 3vw;
    min-width: 64px;
    min-height: 38px;
  }
}
.ant-btn.ant-btn-primary {
  &:disabled {
    background: #8f8f8f;
    color: #fff;
    opacity: 0.5;
  }
}

.fw-600 {
  font-weight: 600;
}
.upload-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  .ant-btn-primary {
    height: 56px;
  }
  .anticon svg {
    fill: white;
  }
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    color: white;
  }
  .ant-upload-list
    .ant-upload-list-item
    .ant-upload-list-item-actions
    .ant-upload-list-item-action.ant-btn {
    display: none;
  }

  .ant-upload-wrapper
    .ant-upload-list
    .ant-upload-list-item-error
    .ant-upload-list-item-name {
    color: white;
  }
}

// .common-notification {
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-message {
  margin-bottom: 0;
  color: white;
  // font-size: 22px;
}
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  // background-color: var(--primary-grey);
  background-color: #7a7575;
  // background: var(--color-primary-background);

  border-radius: 8px;
  padding: 8px;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  color: white;
  top: 9px;
}
